.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: DSEG;
  src: url('~/public/DSEG7Modern-Italic.woff');
  font-style: italic;
}

@font-face {
  font-family: DSEG;
  src: url('~/public/DSEG7Modern-Bold.woff');
  font-weight: bold;
}

@font-face {
  font-family: DSEG14;
  src: url('~/public/DSEG14Modern-Regular.woff');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  height: 100%;
  width: 100%;
  margin: auto;
  background-image:
    linear-gradient(#6d91cd 0 1px, transparent 1px 100%),
    linear-gradient(90deg, #6d91cd 0 1px, transparent 1px 100%);
  background-size: 20px 20px;
  background-color: #74a3ec;
}

.container {
  width: 972px;
  background-color: #efefef;
  height: 100%;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 4%;
  border-radius: 4px;
}

.portfolio-container {
  margin: auto;
  overflow: scroll;
  height: 950px;
  overflow-x: hidden;
  scrollbar-color: white transparent;
  scrollbar-width: thin;
  width: 94%;
}

.inner-container {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-container {
  flex-basis: 14rem;
  flex-grow: 1;
  background-color: #efefef;
  outline: 1px solid black;
  height: min-content;
}

.body-container {
  flex-basis: 0;
  flex-grow: 999;
  background-color: #efefef;
  outline: 1px solid black;
  /* display: flex; */
}

.author-pic {
  display: grid;
  justify-self: center;
  margin: auto;
  padding-top: 4px;
}

.header {
  border-radius: 4px 4px 0 0;
  background-color: #3ad1c5;
  color: #efefef;
  font-family: 'Times New Roman', Times, serif;
  outline: 1px solid black;
  font-size: 14px;
  padding-left: 2%;
}

.body-flex {
  display: flex;
  margin-left: 4px;
  margin-top: 4px;
  height: 660px;
}

.updates-list {
  background-image: linear-gradient(#3ad1c5, white);
  width: 33%;
  margin: 0;
  height: 200px;
  margin-right: 2%;
  overflow-y: auto;
  scrollbar-color: white transparent;
  scrollbar-width: thin;
  border-width: 1px;
  border-color: #2b9b91;
  border-style: solid;
}

h1 {
  margin: 0
}

h2 {
  margin-bottom: 2%;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-size: 20px;
  text-align: right;
  padding-right: 2%;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-left: 0;
  border-top: 0;
}

.collapse,
h3 {
  /* margin: 0; */
  text-align: center;
  background-color: #3ad1c5;
  font-family: 'Times New Roman', Times, serif;
  color: #efefef;
  border-width: 1px;
  /* border-left: 0; */
  border-color: black;
  border-style: solid;
  width: 94%;
  margin: auto;
}

.collapse {
  
  display: block;
  font-size: 1.17em;
  /* margin-top: 1em; */
  /* margin-bottom: 1em; */
  /* margin-left: 0; */
  /* margin-right: 0; */
  font-weight: bold;
  cursor: pointer;
}

h4 {
  margin: 0;
}

li {
  border-bottom: 1px dashed #aaa;
  padding-bottom: 4px;
  padding-top: 4px;
}

a {
  text-decoration: none;
  color: #4a3f3f;
}

a:hover {
  border-radius: 10px;
  border: 1px solid grey;
}

ul {
  padding: 0;
  padding-left: 2%;
  padding-top: 2%;
  margin: 0;
  list-style-position: inside;
}

nav {
  margin: 0;
  padding: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  line-height: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
}

section {
  margin-bottom: 3em;
  margin-right: 2%;
  border-bottom: #282c34 solid 1px;
}

label {
  margin: 0;
  margin-left: 2%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a3f3f;
  letter-spacing: 1px;
}

.sign-entry-field {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

.sign-entry-box {
  resize: none;
  margin: auto;
  margin-left: 1%;
  height: 60px;
}

.sign-button {
  margin: auto;
  margin-left: 1%;
  background-color: #2b9b91;
  border-radius: 4px;
  color: #ffffff;
}

.sign-button:hover {
  cursor: pointer;
  border-color: white;
}

.sidebar-description {
  margin: auto;
  width: 94%;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 4%;
  color: #4a3f3f;
  letter-spacing: 1px;
}

.guestbook-container {
  overflow-y: scroll;
  height: 200px;
}

html{
  scrollbar-color: white transparent;
  scrollbar-width: thin;
}


.counter-container {
  display: block;
  border: black 6px solid;
  border-radius: 9px;
  width: 90%;
  margin: auto;
  margin-top: 2%;
  background-color: #2b9b91;
  box-shadow: 4px 4px 1px 0px rgba(0, 0, 0, 0.1) inset;
  height: 50px;
  cursor: pointer;
}

.visitor-counter {
  color: black;
  position: relative;
  right: 98px;
}

.visitor-counter-background {
  font-family: 'DSEG';
  font-size: 30px;
  position: relative;
  top: 17px;
  color: rgba(0, 0, 0, 0.15);
}

.visitor-counter-header {
  color: black;
  position: relative;
  right: 104px;
}

.visitor-counter-header-background {
  z-index: 50;
  color: rgba(0, 0, 0, 0.15);
  font-family: 'DSEG14';
  margin: 0;
  font-size: 16px;
  position: absolute;
  margin-bottom: 2px;
}

.guestbook-timestamp {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: italic;
  padding-bottom: 1%;
  padding-top: 2%;
}

.guestbook-name {
  margin: 0;
  padding-bottom: 1%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.guestbook-message {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1px;
  color: #282c34;
  padding-bottom: 2%;
}

.background-image {
  width: 100%;
  height: 20%;
  background-image: url('~/public/background.jpg');
  background-size: cover;
  overflow: hidden;
}

.title-banner {
  display: flex;
}

.title-banner-container {
  display: flex;
  white-space: nowrap;
  padding: 8px 0;
}

.title-banner-entry {
  padding: 0 30px;
}

.title-banner-container>div {
  animation: scroll-banner infinite linear;
}

@keyframes scroll-banner {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}